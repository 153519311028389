import { parseString } from "@/lib";

export function decodeStakeholder(serverStakeholder) {
  const { celular, codigo, email, id, nome, perfil, tipoCodigo } = serverStakeholder;
  const { ordem, papel, resposta: respostaServer } = perfil;
  const resposta = decodeApproval(respostaServer);

  return { celular, codigo, email, id, nome, ordem, papel, resposta, tipoCodigo };
}

function decodeApproval(approvalServer) {
  if (!approvalServer) return null;

  const { dataResposta, parecer: parentParecer, status } = approvalServer;
  const { interessadosIds, parecer: parecerSerialized } = parentParecer ?? { interessadosIds: [], parecer: null };
  // mesmo sendo string, o valor em parecer pode não parseable
  const parecer = parseString(parecerSerialized);

  return { dataResposta, interessadosIds, parecer, status };
}

export function encodeStakeholder(clientStakeholder) {
  const { celular, codigo, email, id, nome, ordem, papel, tipoCodigo } = clientStakeholder;
  return {
    celular,
    codigo,
    email,
    id,
    nome,
    perfil: { ordem, papel },
    tipoCodigo,
  };
}
