import { decodeFlow, encodeFlow } from "./code-flow";

export function createFlowsEndpoints({ http, pageLimit }) {
  const flowsUrl = "/v1/fluxos";

  return {
    flowAdd: http.endpoint({
      decode: decodeFlow,
      encode: clientFlow => ({ payload: encodeFlow(clientFlow), url: flowsUrl }),
      method: "post",
    }),

    flowArchive: http.endpoint({
      decode: decodeFlow,
      encode: flowId => ({ url: `${flowsUrl}/${flowId}/arquivar` }),
      method: "patch",
    }),

    flowCancel: http.endpoint({
      decode: decodeFlow,
      encode: flowId => ({ url: `${flowsUrl}/${flowId}/cancelar` }),
      method: "patch",
    }),

    flowEdit: http.endpoint({
      decode: decodeFlow,
      encode: clientFlow => ({ payload: encodeFlow(clientFlow), url: `${flowsUrl}/${clientFlow.id}` }),
      method: "put",
    }),

    flowFinish: http.endpoint({
      decode: decodeFlow,
      encode: flowId => ({ url: `${flowsUrl}/${flowId}/finalizar` }),
      method: "patch",
    }),

    flowHashes: http.endpoint({
      encode: ({ algorithm, flowId }) => ({ url: `${flowsUrl}/${flowId}/hashes-documentos/${algorithm}` }),
      method: "get",
    }),

    flowLinkSend: http.endpoint({
      encode: ({ idFluxo, idRequisitante, interessado }) => ({ payload: { idFluxo, idRequisitante, interessados: [interessado] }, url: "/v1/reenviar-links" }),
      method: "patch",
    }),

    flowReject: http.endpoint({
      decode: decodeFlow,
      encode: flowId => ({ url: `${flowsUrl}/${flowId}/rejeitar` }),
      method: "patch",
    }),

    flowReviewDraft: http.endpoint({
      encode: ({ feedback, flowId }) => ({ payload: feedback, url: `${flowsUrl}/${flowId}/revisao` }),
      method: "patch",
    }),

    flowReviewSubmit: http.endpoint({
      encode: ({ feedback, flowId }) => ({ payload: feedback, url: `${flowsUrl}/${flowId}/revisar` }),
      method: "patch",
    }),

    flowSignAdvanced: http.endpoint({
      decode: decodeFlow,
      encode: flowId => ({ url: `${flowsUrl}/${flowId}/assinar` }),
      method: "patch",
    }),

    flowSignSerproId: http.endpoint({
      decode: decodeFlow,
      encode: ({ flowId, ...payload }) => ({ payload, url: `${flowsUrl}/${flowId}/assinar-serpro-id` }),
      method: "patch",
    }),

    flowSignSerproSigner: http.endpoint({
      decode: decodeFlow,
      encode: ({ algorithm, documents, flowId }) => ({ payload: { documentos: documents }, url: `${flowsUrl}/${flowId}/assinar-desktop/${algorithm}` }),
      method: "patch",
    }),

    flowStart: http.endpoint({
      decode: decodeFlow,
      encode: flowId => ({ url: `${flowsUrl}/${flowId}/iniciar` }),
      method: "patch",
    }),

    flowsArchivedList: http.endpoint({
      decode: ({ data, pagination }) => ({ data: data.map(decodeFlow), pagination }),
      encode: page => ({ url: `${flowsUrl}/arquivados?limit=${pageLimit}&offset=${page * pageLimit}` }),
      method: "get",
    }),

    flowsEngagementList: http.endpoint({
      decode: ({ data, pagination }) => ({ data: data.map(decodeFlow), pagination }),
      encode: page => ({ url: `/v1/respostas?limit=${pageLimit}&offset=${page * pageLimit}` }),
      method: "get",
    }),

    flowsManagementList: http.endpoint({
      decode: ({ data, pagination }) => ({ data: data.map(decodeFlow), pagination }),
      encode: page => ({ url: `${flowsUrl}?limit=${pageLimit}&offset=${page * pageLimit}` }),
      method: "get",
    }),
  };
}
