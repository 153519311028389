import { AUTH_STATUSES } from "@/data/auth";

export const stateKey = "sign";

export const initialState = {
  AUTH_STATUSES,
  expiration: null,
  status: AUTH_STATUSES.UNSOLVED,
  token: null,
};

export const getters = {
  expiration: state => state[stateKey].expiration,
  isSignedIn: (state, { status }) => status === AUTH_STATUSES.SIGNED_IN,
  isSignedOut: (state, { status }) => status === AUTH_STATUSES.SIGNED_OUT,
  isUnsolved: (state, { status }) => status === AUTH_STATUSES.UNSOLVED,
  status: state => state[stateKey].status,
  token: state => state[stateKey].token,
};

export const mutations = {
  setExpiration(state, expirationAsDateString = null) {
    let serverSessionExpiration = null;
    if (expirationAsDateString) {
      serverSessionExpiration = new Date(expirationAsDateString);
    }

    let safeSessionExpiration = null;
    if (serverSessionExpiration) {
      /*
        arbitrando um cap máximo de sessão sobre o token:
        - simplifica testes sem impacto evidente na experiência do usuário,
          já que os token com longa duração são usados apenas em cenários de desenvolvimento.
        - contorna limitações da linguagem em lidar com grande números em timeouts.
          https://stackoverflow.com/questions/3468607/why-does-settimeout-break-for-large-millisecond-delay-values
      */
      const nowPlusMaxTime = new Date();
      const maxTime = 2 * 60 * 60 * 1000; // duas horas
      nowPlusMaxTime.setTime(nowPlusMaxTime.getTime() + maxTime);
      safeSessionExpiration
        = nowPlusMaxTime < serverSessionExpiration
          ? nowPlusMaxTime
          : serverSessionExpiration;
    }
    state[stateKey].expiration = safeSessionExpiration;
  },

  setStatusSignIn(state) {
    state[stateKey].status = AUTH_STATUSES.SIGNED_IN;
  },

  setStatusSignOut(state) {
    state[stateKey].status = AUTH_STATUSES.SIGNED_OUT;
  },

  setToken(state, token = null) {
    state[stateKey].token = token;
  },
};

export function createSignActions(endpoints) {
  return {
    async  signIn({ commit, dispatch, getters }, { perfil }) {
    // essa action é usada durante a autenticação inicial o que provoca uma chamada ao backend ou para troca de perfil durante o uso da aplicação o que equivale a um login no frontend mas dispensa a ida ao backend para recuperar um token de autenticação. é importante perceber que a troca de perfil durante uma sessão não se limita a trocar o perfil na store, tb existem outros efeitos colaterais a serem assegurados, daí a ação de signIn ainda ser o recurso correto a ser chamado.
      if (!getters.isSignedIn) {
        const payload = {
          idCliente: getters.idCliente,
          idConta: getters.idConta,
          perfil,
        };
        const sessionResponse = await endpoints.tokenAcquisition.request(payload);

        const { expiracao: expirationAsDateString, token } = sessionResponse;
        commit("setToken", token);
        commit("setExpiration", expirationAsDateString);
        commit("setStatusSignIn");
      }

      // certos comportamentos reativos na UI observam o status de signIn e do perfil para controlar comportamento. é importante que a atualização venha após a dia ao backend no trecho anterior de forma que a atualização do status de signIn e do perfil sejam sequenciais. ou seja, o commit abaixo não pode ser antes do request ao backend.
      commit("setPerfil", perfil);
      await dispatch("persistAuthInStorage");
    },

    signOut({ commit, dispatch }) {
      dispatch("eraseAuthFromStorage");
      commit("resetState");
      commit("setStatusSignOut");
    },
  };
}
