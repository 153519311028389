<template>
  <ButtonPrimary
    field="send"
    :hide="!isCriado(fluxo)"
    label="Enviar para Assinatura"
    type="button"
    @click="attemptTostartFlow(fluxo)"
  />
</template>

<script>
import {
  hasAssinante,
  hasDadosBasicos,
  hasIdleUploadQueue,
  hasPendingSeloSetup,
  hasSomeUploadedDocument,
  isCriado,
} from "@/data";
import { ButtonPrimary } from "@/lib";

export default {
  components: {
    ButtonPrimary,
  },
  methods: {
    async attemptTostartFlow(fluxo) {
      const pendencies = this.derivePendencies(fluxo);
      if (pendencies.length > 0) {
        return this.$showAlert("Não é possível iniciar o fluxo", {
          messageDetail: pendencies,
        });
      }

      const message
        = "Após envio não será possível realizar alterações nos dados do fluxo. Confirma envio para assinatura?";
      const hasConfirmed = await this.$showAlert(message, {
        enableCancel: true,
      });
      if (!hasConfirmed) return;

      this.executestartFlowCommand(fluxo);
    },
    derivePendencies(fluxo) {
      const allPendencies = [];
      if (!isCriado(fluxo)) {
        allPendencies.push("O status precisa ser CRIADO.");
      }

      if (!hasDadosBasicos(fluxo)) {
        allPendencies.push(
          "Os dados básicos devem estar preenchidos com nome, descrição e grupo.",
        );
      }

      if (!hasIdleUploadQueue(fluxo)) {
        allPendencies.push("Ainda há upload de documento pendente.");
      }

      if (!hasSomeUploadedDocument(fluxo)) {
        allPendencies.push("É necessário pelo menos um documento anexado.");
      }

      if (!hasAssinante(fluxo)) {
        allPendencies.push("É necessário pelo menos um assinante associado.");
      }

      if (hasPendingSeloSetup(fluxo)) {
        allPendencies.push(
          "Nem todos os documentos estão com os selos completamente posicionados. Vá a aba documentos e aponte a posição do selo de assinatura em todos os documentos anexados ao fluxo.",
        );
      }

      return allPendencies;
    },
    executestartFlowCommand(fluxo) {
      const command = {
        onExecute: f =>
          this.$store.dispatch("fluxosAdministrativo/startFlow", f),
        onSuccess: () => {
          this.$notifySuccess("Fluxo enviado para assinatura com sucesso");
          this.$router.push("/flows-management/list");
        },
      };

      this.execute(command, fluxo);
    },
    isCriado,
  },
  name: "FlowManagementStartButton",
  props: {
    execute: {
      required: true,
      type: Function,
    },
    fluxo: {
      required: true,
      type: Object,
    },
  },
};
</script>
