import axios from "axios";

import { throwBadRequest } from "./throw";
import { injectToken } from "./token";

export function createRequest({ baseUrl, tokenOrGetter } = {}) {
  const client = axios.create({
    baseURL: baseUrl,
    validateStatus: () => true,
  });

  if (tokenOrGetter) injectToken({ client, tokenOrGetter });

  return async (options) => {
    const { headers, method = "get", payload, responseType, url } = options;
    let response;
    try {
      response = await client({ data: payload, headers, method, responseType, url });
      if (response.status >= 300) throw new Error("force catch clause");
    }
    catch {
      throwBadRequest({ ...response, method, url });
    }

    return response.data;
  };
}
