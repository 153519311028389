/**
 * Creates an endpoint object with a request method.
 *
 * @param {Object} config - The configuration object.
 * @param {Function} [config.decode] - A function to decode the response data.
 * @param {Function} [config.encode] - A function to encode the request options. The function must return an object with payload and url properties.
 * @param {Object} config.headers - The headers to include in the request.
 * @param {string} config.method - The HTTP method to use for the request.
 * @param {Function} config.request - The function to perform the request.
 * @param {string} config.responseType - The type of response expected.
 * @param {string} config.url - The URL to send the request to. Will be ignored if an encode function is provided.
 * @returns {Object} The endpoint object with a request method.
 */
export function createEndpoint({ decode, encode, headers, method, request, responseType, url: urlCreate }) {
  const endpoint = {
    requested: false,
    requesting: false,
  };

  endpoint.request = async (options) => {
    const { payload, url } = typeof encode === "function" ? encode(options) : { payload: options, url: urlCreate };
    try {
      endpoint.requesting = true;
      const response = await request({ headers, method, payload, responseType, url });
      return typeof decode === "function" ? decode(response) : response;
    }
    finally {
      endpoint.requesting = false;
      endpoint.requested = true;
    }
  };

  return endpoint;
}
