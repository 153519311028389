import { AppError } from "@/lib/error";
import { SYSLOG_SEVERITIES } from "@/lib/severities";

export function throwBadRequest(options = {}) {
  const { data = {}, method, status, statusText, url } = options;
  const code = data.code || status;

  // statusText também poderia ser considerado como alternativa de mensagem mas foi abandonado por ser normalmente um texto técnico em inglês
  const message = data.message || "Aconteceu um erro inesperado na aplicação durante a requisição.";

  const severity = status < 500 ? SYSLOG_SEVERITIES.WARNING : SYSLOG_SEVERITIES.ERROR;
  const meta = {
    ...data,
    isHttp: true,
    method,
    requestUrl: url,
    status,
    statusText,
  };
  AppError.throw({ code, message, meta, severity });
}
