import { createLogger, handleVue2Error, handleWindowOnerror, handleWindowRejection } from "@/lib";

import { createEffects } from "./effects";
import { userData } from "./user";
import { silenceUnwantedWarnings } from "./warnings";

export function initLogger({ environment, globals, store, webLoggerEndpoint }) {
  const effects = createEffects({ environment, webLoggerEndpoint });

  const meta = () => ({
    ...environment.hash(),
    ...userData(store),
  });

  const logger = createLogger({ effects, meta, size: 100 });

  globals.Vue.config.errorHandler = handleVue2Error(logger);
  globals.Vue.config.warnHandler = silenceUnwantedWarnings(logger);
  globals.window.onerror = handleWindowOnerror(logger);
  globals.window.onunhandledrejection = handleWindowRejection(logger);

  return logger;
}
