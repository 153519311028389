export function createAuthEndpoints(http) {
  const linkAuthentication = http.endpoint({
    method: "patch",
    url: "/v1/link",
  });

  const otpVerification = http.endpoint({
    method: "patch",
    url: "/v1/verificar-otp",
  });

  const otpTrigger = http.endpoint({
    method: "patch",
    url: "/v1/enviar-otp",
  });

  const listAccounts = http.endpoint({
    encode: email => ({ url: `/v1/listar-contas/${email}` }),
    method: "get",
  });

  const accountConfirmation = http.endpoint({
    encode: idConta => ({ url: `/v1/confirmacao-conta/${idConta}` }),
    method: "get",
  });

  const tokenAcquisition = http.endpoint({
    method: "put",
    url: "/v1/token",
  });

  // é usado para buscar opções genéricas de uso de web authn para preenchimento automático de autocomplete de contas já presentes no navegador
  const passkeyOptions = http.endpoint({
    method: "get",
    url: "/v1/authentication-options",
  });

  // valida contra o backend a resposta fornecida pelo autenticador web authn do navegador ou sistema operacional. o objetivo é concluir o registro de um novo dispositivo web authn vinculado a uma conta junto ao backend.
  const passkeyRegistration = http.endpoint({
    method: "post",
    url: "/v1/verify-registration",
  });

  // valida contra o backend a resposta fornecida pelo autenticador web authn do navegador ou sistema operacional. o objetivo é concluir a autenticação com um dispositivo web authn vinculado anteriormente a essa conta junto ao backend.
  const passkeyVerification = http.endpoint({
    method: "post",
    url: "/v1/verify-authentication",
  });

  return {
    accountConfirmation,
    linkAuthentication,
    listAccounts,
    otpTrigger,
    otpVerification,
    passkeyOptions,
    passkeyRegistration,
    passkeyVerification,
    tokenAcquisition,
  };
}
