var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ButtonPrimary", {
    attrs: {
      field: "send",
      hide: !_vm.isCriado(_vm.fluxo),
      label: "Enviar para Assinatura",
      type: "button",
    },
    on: {
      click: function ($event) {
        return _vm.attemptTostartFlow(_vm.fluxo)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }