export function selectFilesFromDialog({ logger, parentEl }) {
  // alguns usuário em contexto de configuração extraordinários enfretaram dificuldades para realizar upload de arquivos. esses logs em nível de debug ajudam a depurar esses cenários.

  return new Promise((resolve) => {
    const inputFile = document.createElement("input");
    inputFile.type = "file";

    /*
      o input é incluído no DOM para possibilitar que testes automáticos
      encontrem o elemento por meio de 'input[type=file]'
    */
    inputFile.style.display = "none";
    logger.log(`o input sintético para exibir função de seleção de arquivo ${inputFile} foi incluído no dom sob elemento ${parentEl}`);
    parentEl.appendChild(inputFile);

    inputFile.onchange = (e) => {
      const files = Array.from(e.target.files);
      logger.log(`detectado evento de mudança no input de arquivos e retornando controle para contexto que chamou a função de seleção retornando ${files.length} arquivos`);
      resolve(files);
      inputFile.remove();
    };

    inputFile.oncancel = () => {
      logger.log("detectado cancelamento da seleção de arquivos. a função de seleção será resolvida com um array vazio.");
      resolve([]);
      inputFile.remove();
    };

    inputFile.click();
  });
}
