import { createHttp } from "@/lib";

import { decodeDocument } from "./code";

export function createDocumentsEndpoints(backendHttp) {
  const downloadHttp = createHttp();
  const documentsUrl = "/v1/documentos";

  return {
    documentDelete: backendHttp.endpoint({
      encode: id => ({ url: `${documentsUrl}/${id}` }),
      method: "delete",
    }),

    documentDownload: downloadHttp.endpoint({
      encode: url => ({ url }), // a url de download é dinâmicamente gerada pelo backend e possui tempo de expiração. ela é passada pelo backend como retorno de chamadas como criação de fluxo ou anexação de documento. aqui colocamos como função para permitir que quem utilize o endpoint possa passar a url completa no momento da requisição.
      method: "get",
      responseType: "blob",
    }),

    documentUpload: backendHttp.endpoint({
      decode: decodeDocument,
      encode: ({ file, idFluxo }) => ({ payload: { doc: file, idFluxo }, url: documentsUrl }),
      headers: { "Content-Type": "multipart/form-data" },
      method: "post",
    }),
  };
}
