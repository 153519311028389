import { createHttp } from "@/lib";

export function createBackendHttp({ environment, getToken }) {
  const environmentBaseUrl = environment.get("WEB_API_BASE_URL");
  const baseUrl = environmentBaseUrl ?? resolveBaseUrl();
  const api = createHttp({ baseUrl, tokenOrGetter: getToken });
  return api;
}

function resolveBaseUrl() {
  let origin = window.location.origin;

  // esse hostname é o percebido pelos tests e2e rodando dentro do container
  if (origin.includes("web-app")) origin = "http://api-gateway:8080";

  return `${origin}/api`;
}
