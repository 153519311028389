export function encodeClient(frontendClient) {
  // adapta campos de acordo com sintaxe do payload exigido pelo backend durante inclusão
  return {
    contratante: {
      estrangeiro: false,
      identificador: frontendClient.cliente.cnpj,
      nome: frontendClient.cliente.nome,
      sigla: frontendClient.cliente.sigla,
      tecnicosProducao: [
        {
          cpf: frontendClient.gestor.codigo,
          email: frontendClient.gestor.email,
          nome: frontendClient.gestor.nome,
          paisIso3: "BRA",
          telefone: frontendClient.gestor.telefone,
          telefoneSecundario: "",
          tipoCodigo: frontendClient.gestor.tipoCodigo,
        },
      ],
      tipoCliente: "PJ",
    },
  };
}
