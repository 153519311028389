export function turnOnModules(store, modules = []) {
  modules.forEach(module => turnOnModule(store, module));
}

export function turnOffModules(store, modules = []) {
  modules.forEach(module => turnOffModule(store, module));
}

function turnOnModule(store, module) {
  runIfExistis({ action: "onTurnOn", module, store });
}

function turnOffModule(store, module) {
  if (!store.hasModule(module)) return;
  runIfExistis({ action: "onTurnOff", module, store });
  runIfExistis({ module, mutation: "resetState", store });
}

function runIfExistis({ action, module, mutation, store }) {
  if (action) dispatchIfExists({ action, module, store });
  if (mutation) commitIfExists({ module, mutation, store });
}

function dispatchIfExists({ action, module, store }) {
  const path = `${module}/${action}`;
  if (store._actions[path]) store.dispatch(path);
}

function commitIfExists({ module, mutation, store }) {
  const path = `${module}/${mutation}`;
  if (store._mutations[path]) {
    store.commit(path);
  }
}
