import { decodeRequester, encodeRequester } from "./code-requester";

export function createRequestersEndpoints({ http, pageLimit }) {
  const requestersUrl = "/v1/contas/perfil/REQUISITANTE";

  return {
    requesterAdd: http.endpoint({
      decode: decodeRequester,
      encode: clientRequester => ({ payload: encodeRequester(clientRequester), url: requestersUrl }),
      method: "post",
    }),

    requesterEdit: http.endpoint({
      decode: decodeRequester,
      encode: clientRequester => ({ payload: encodeRequester(clientRequester), url: `${requestersUrl}/${clientRequester.id}` }),
      method: "put",
    }),

    requesterWebhookTest: http.endpoint({
      encode: requesterId => ({ url: `/v1/webhook/validar/${requesterId}` }),
      method: "get",
    }),

    requestersList: http.endpoint({
      decode: ({ data, pagination }) => ({ data: data.map(decodeRequester), pagination }),
      encode: page => ({ url: `${requestersUrl}?limit=${pageLimit}&offset=${page * pageLimit}` }),
      method: "get",
    }),
  };
}
