import { createCollectionStoreModule } from "@/lib";

export function createInteressadosStore({ endpoints, pollingSeconds }) {
  return createCollectionStoreModule({
    actions: {
      async addStakeholder({ commit }, userStakeholder) {
        const responseStakeholder = await endpoints.stakeholderAdd.request(userStakeholder);
        commit("setItem", responseStakeholder);
      },

      async editStakeholders({ commit }, userStakeholder) {
        const responseStakeholder = await endpoints.stakeholderEdit.request(userStakeholder);
        commit("setItem", responseStakeholder);
      },
    },
    pollingSeconds,
    queryEndpoint: endpoints.stakeholdersList,
  });
}
