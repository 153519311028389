import { decodeDocument, encodeDocument } from "../documents";
import { decodeStakeholder, encodeStakeholder } from "./code-stakeholder";

export function decodeFlow(serverFlow) {
  const { dadosBasicos, documentos, id, interessados, ...recordRest } = serverFlow;
  const { createdAt, interrompeSeNegativo, sequencial, tipoAssinatura, ...basicosRest } = dadosBasicos;

  const interessadosClient = interessados.map(decodeStakeholder);

  return {
    id,
    ...recordRest,
    dadosBasicos: {
      dataCriacao: new Date(createdAt),
      isIcp: tipoAssinatura === "QUALIFICADA",
      isInterrompeSeNegativo: interrompeSeNegativo,
      isSequencial: sequencial,
      ...basicosRest,
    },
    documentos: documentos.map(decodeDocument),
    interessados: interessadosClient,
  };
}

export function encodeFlow(clientFlow) {
  const { dadosBasicos, documentos = [], interessados = [], ...itemRest } = clientFlow;
  const { isIcp, isInterrompeSeNegativo, isSequencial, ...basicosRest } = dadosBasicos;

  const record = {
    ...itemRest,
    dadosBasicos: {
      interrompeSeNegativo: isInterrompeSeNegativo,
      sequencial: isSequencial,
      tipoAssinatura: isIcp ? "QUALIFICADA" : "AVANCADA",
      ...basicosRest,
    },
    documentos: documentos.map(encodeDocument),
    interessados: interessados.map(encodeStakeholder),
  };
  return record;
}
