import { createCollectionStoreModule } from "@/lib";

export function createClientesStore({ endpoints, pollingSeconds }) {
  return createCollectionStoreModule({
    actions: {
      async addClient({ commit }, userClient) {
        const responseClient = await endpoints.clientAdd.request(userClient);
        commit("setItem", responseClient);
      },
      async editClient({ commit }, userClient) {
        const responseClient = await endpoints.clientEdit.request(userClient);
        commit("setItem", responseClient);
      },
    },
    pollingSeconds,
    queryEndpoint: endpoints.clientsList,
  });
}
