import { AppError, SYSLOG_SEVERITIES } from "@/lib";
import { browserSupportsWebAuthn, startAuthentication as startAuthenticationWebAuthn, startRegistration as startRegistrationWebAuthn } from "@simplewebauthn/browser";

export const stateKey = "webAuthn";

export const initialState = {
  authenticationOptions: null,
  authenticatorResponse: null,
  isRegistrationPending: true,
  registrationOptions: null,
  skippedAuthentication: false,
};

export const getters = {
  isWebAuthnAuthenticatorAvailable: (state, { isWebAuthnManuallySkippedAuthentication, isWebAuthnSupported, webAuthnAuthenticationOptions }) => {
    if (!isWebAuthnSupported || !webAuthnAuthenticationOptions) return false;
    if (isWebAuthnManuallySkippedAuthentication) return false;

    // se houver algum registro em "allowCredentials" significa que o usuário já cadastrou algum dispositivo de autenticação junto ao backend em sessão anterior.
    const { allowCredentials } = webAuthnAuthenticationOptions;
    return allowCredentials.length > 0;
  },
  isWebAuthnManuallySkippedAuthentication: state => state[stateKey].skippedAuthentication,
  isWebAuthnRegistrationPending: state => state[stateKey].isRegistrationPending,
  isWebAuthnSupported: () => browserSupportsWebAuthn(),
  webAuthnAuthenticationOptions: state => state[stateKey].authenticationOptions,
  webAuthnAuthenticatorResponse: state => state[stateKey].authenticatorResponse,
  webAuthnRegistrationOptions: state =>
    state[stateKey].registrationOptions,
};

export const mutations = {
  setWebAuthnAuthenticationOptions(state, options = {}) {
    state[stateKey].authenticationOptions = options;
  },

  setWebAuthnAuthenticatorResponse(state, setAuthenticatorResponse) {
    state[stateKey].authenticatorResponse = setAuthenticatorResponse;
  },

  setWebAuthnRegistrationDone(state) {
    state[stateKey].isRegistrationPending = false;
  },

  setWebAuthnRegistrationOptions(state, options = {}) {
    state[stateKey].registrationOptions = options;
  },

  setWebAuthnSkippedAuthentication(state) {
    state[stateKey].skippedAuthentication = true;
  },
};

export function createWebAuthnActions(endpoints) {
  return {
    async activateWebAuthnConditionalUi({ commit, dispatch, getters }) {
      if (!getters.isWebAuthnSupported) return;

      const authenticationOptions = await endpoints.passkeyOptions.request();
      commit("setWebAuthnAuthenticationOptions", authenticationOptions);

      await dispatch("startAuthentication", { autocomplete: true });
      if (getters.webAuthnAuthenticatorResponse) {
        await dispatch("verifyAuthentication");
      }
    },

    async authenticateWithWebAuthn({ dispatch, getters }) {
      await dispatch("startAuthentication");
      if (getters.webAuthnAuthenticatorResponse) {
        await dispatch("verifyAuthentication");
      }
    },

    async registerAuthenticator({ commit, getters: { idConta, webAuthnRegistrationOptions } }) {
      const authenticatorResponse = await startRegistrationWebAuthn(webAuthnRegistrationOptions);
      commit("setWebAuthnAuthenticatorResponse", authenticatorResponse);
      const payload = {
        authenticatorResponse,
        idConta,
      };
      await endpoints.passkeyRegistration.request(payload);
      commit("setWebAuthnRegistrationDone");
    },

    async startAuthentication({ commit, getters: { isWebAuthnSupported, webAuthnAuthenticationOptions } }, { autocomplete = false } = {}) {
    // action auxiliar responsável pela comunicação com o autenticador gerenciado pelo navegador. ela é usada por outras ações que cobrem a autenticação como um todo.

      if (!isWebAuthnSupported) return;

      let authenticatorResponse;
      try {
        authenticatorResponse = await startAuthenticationWebAuthn(webAuthnAuthenticationOptions, autocomplete);
      }
      catch (e) {
      // o erro aconteceu ao tentar preencher a UI com a lista de authenticators salvos no navegador. não temos muitos recursos pra entender o que levou ao erro, pode ser, por exemplo, que não haja chaves salvas ainda. daí suprimimos a exceção.
        if (autocomplete) return;

        AppError.throw({
          message: "Não foi possível autenticar usando o dispositivo. Tente novamente ou use um canal de comunicação para envio do código de segurança",
          meta: { property: "form" },
          severity: SYSLOG_SEVERITIES.ERROR,
        });
      }

      commit("setWebAuthnAuthenticatorResponse", authenticatorResponse);
    },

    // verifyAuthentication é uma ação intermediária que trata da comunicação com o backend. é compartilhada por outras ações que cobrem a autenticação completa via web-authn
    async verifyAuthentication({ commit, getters: { webAuthnAuthenticatorResponse } }) {
      const payload = {
        authenticatorResponse: webAuthnAuthenticatorResponse,
      };

      const {
        cliente: { id: idCliente, nome: nomeCliente, sigla },
        conta: { codigo, email, id: idConta, nome: nomeConta, perfis },
        expiracao: expirationAsDateString,
        token,
      } = await endpoints.passkeyVerification.request(payload);

      if (perfis.length <= 0) {
        throw new Error("Não é possível realizar a autenticação pois usuário não possui perfil vinculado");
      }

      // alguns atributos são salvo no state mesmo que possivelmente tenham sido tratados em rotas anteriores. isso acontece porque os fluxos de autenticação seguem ordem de requisições diferentes e nesse momento é possível que certos campos do state ainda estejam indefinidos.
      const conta = { id: idConta, idCliente };
      const cliente = { id: idCliente, nome: nomeCliente, sigla };
      commit("setContas", [conta]);
      commit("setClientes", [cliente]);
      commit("setCodigo", codigo);
      commit("setEmail", email);
      commit("setNome", nomeConta);
      commit("setCliente", idCliente);
      commit("setConta", idConta);
      commit("setToken", token);
      commit("setExpiration", expirationAsDateString);
      commit("setPerfis", perfis);

      // se o usuário fez login com web authn considera-se que não há mais pendência de registro de dispositivo
      commit("setWebAuthnRegistrationDone");
    },

  };
}
