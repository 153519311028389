import { createAccountsEndpoints } from "../accounts";
import { createAuthEndpoints } from "../auth";
import { createBackendHttp } from "../backend-http";
import { createClientsEndpoints } from "../clients";
import { createDocumentsEndpoints } from "../documents";
import { createFlowsEndpoints } from "../flows";
import { createGroupsEndpoints } from "../groups";
import { createHelloEndpoint } from "../hello";
import { createwebLoggerEndpoint } from "../logger";
import { createManagersEndpoints } from "../managers";
import { createRequestersEndpoints } from "../requesters";
import { createStakeholdersEndpoints } from "../stakeholders";

export function initApisEndpoints({ environment, getToken }) {
  const pageLimit = environment.get("WEB_PAGINATION_LIMIT", 100);
  const backendHttp = createBackendHttp({ environment, getToken });

  return {
    hello: createHelloEndpoint(backendHttp),
    logger: createwebLoggerEndpoint({ environment }),
    ...createAccountsEndpoints(backendHttp),
    ...createAuthEndpoints(backendHttp),
    ...createClientsEndpoints({ http: backendHttp, pageLimit }),
    ...createDocumentsEndpoints(backendHttp),
    ...createFlowsEndpoints({ http: backendHttp, pageLimit }),
    ...createGroupsEndpoints({ http: backendHttp, pageLimit }),
    ...createManagersEndpoints({ http: backendHttp, pageLimit }),
    ...createStakeholdersEndpoints({ http: backendHttp, pageLimit }),
    ...createRequestersEndpoints({ http: backendHttp, pageLimit }),
  };
}
