import { decodeManager, encodeManager } from "./code";

export function createManagersEndpoints({ http, pageLimit }) {
  const managersUrl = "/v1/contas/perfil/GESTOR";
  return {
    managerAdd: http.endpoint({
      decode: decodeManager,
      encode: clientManager => ({ payload: encodeManager(clientManager), url: managersUrl }),
      method: "post",
    }),

    managerEdit: http.endpoint({
      decode: decodeManager,
      encode: clientManager => ({ payload: encodeManager(clientManager), url: `${managersUrl}/${clientManager.id}` }),
      method: "put",
    }),

    managersList: http.endpoint({
      decode: ({ data, pagination }) => ({ data: data.map(decodeManager), pagination }),
      encode: page => ({ url: `${managersUrl}?limit=${pageLimit}&offset=${page * pageLimit}` }),
      method: "get",
    }),
  };
}
