import { createCollectionStoreModule } from "@/lib";

export function createGruposStore({ endpoints, pollingSeconds }) {
  return createCollectionStoreModule({
    actions: {
      async addGroup({ commit }, userGroup) {
        const responseGroup = await endpoints.groupAdd.request(userGroup);
        commit("setItem", responseGroup);
      },
      async editGroup({ commit }, userGroup) {
        const responseGroup = await endpoints.groupEdit.request(userGroup);
        commit("setItem", responseGroup);
      },
    },
    getters: {
      membrosOfGrupo: (state, getters, rootState, rootGetters) => (grupoId) => {
        const grupo = getters.getItemById(grupoId);
        if (!grupo) return [];

        const { gestores: gestoresIds, requisitantes: requisitantesIds } = grupo;
        const requisitantes = rootGetters["requisitantes/filterItemsByFieldWithSomeValue"]("id", requisitantesIds);
        const gestores = rootGetters["gestores/filterItemsByFieldWithSomeValue"]("id", gestoresIds);

        const membros = requisitantes.concat(gestores);
        return membros;
      },
    },
    pollingSeconds,
    queryEndpoint: endpoints.groupsList,
  });
}
