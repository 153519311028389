import { isProfileAnyOf, isProfileNoneOf } from "@/data/auth";

export const stateKey = "profile";

export const initialState = {
  clientes: [],
  codigo: null,
  contas: [],
  email: null,
  idCliente: null,
  idConta: null,
  nome: null,
  perfil: null,
  perfis: [],
};

export const getters = {
  cliente: (state, { clientes, idCliente }) => clientes.find(({ id }) => id === idCliente),
  clientes: state => state[stateKey].clientes,
  codigo: state => state[stateKey].codigo,
  contaOfCliente: (state, { contas }) => idCliente => contas.find(conta => conta.idCliente === idCliente),
  contas: state => state[stateKey].contas,
  email: state => state[stateKey].email,
  hasPerfis: (state, { perfis }) => perfis.length > 0,
  idCliente: state => state[stateKey].idCliente,
  idConta: state => state[stateKey].idConta,
  isClienteSetPending: (state, { clientes, idCliente }) => clientes.length > 0 && !idCliente, // sinaliza que já existe uma lista de clientes carregada do backend mas o usuário ainda não selecionou o cliente que usará na sessão
  nome: state => state[stateKey].nome,
  nomeCliente: (state, { cliente }) => cliente?.nome || null,
  perfil: state => state[stateKey].perfil || "NONE",
  perfilIfSignedIn: (state, { isSignedIn, perfil }) => {
    if (!isSignedIn) return null;
    return perfil;
  },
  perfilIsAnyOf: (state, { perfil }) => profileOrProfiles => isProfileAnyOf(perfil, profileOrProfiles),
  perfilIsNoneOf: (state, { perfil }) => profileOrProfiles => isProfileNoneOf(perfil, profileOrProfiles),
  perfis: (state, { isClienteSetPending }) => {
    if (isClienteSetPending) return [];

    const { perfis } = state[stateKey];
    if (!Array.isArray(perfis)) return [];

    return perfis;
  },
};

export const mutations = {
  setCliente(state, idCliente = null) {
    state[stateKey].idCliente = idCliente;
  },

  setClientes(state, clientes = []) {
    clientes.sort((a, b) => (a.nome > b.nome ? 1 : -1));
    state[stateKey].clientes = clientes;
  },

  setCodigo(state, codigo = null) {
    state[stateKey].codigo = codigo;
  },

  setConta(state, idConta = null) {
    state[stateKey].idConta = idConta;
  },

  setContas(state, contas = []) {
    state[stateKey].contas = contas;
  },

  setEmail(state, email = null) {
    state[stateKey].email = email;
  },

  setNome(state, nome = null) {
    state[stateKey].nome = nome;
  },

  setPerfil(state, perfil = null) {
    state[stateKey].perfil = perfil;
  },

  setPerfis(state, perfis = []) {
    state[stateKey].perfis = perfis;
  },
};

export function createProfileActions(endpoints) {
  return {
    async listContas({ commit }, { email }) {
      const contasAndClientes = await endpoints.listAccounts.request(email);

      const contas = contasAndClientes.map(({ idCliente, idConta: id }) => ({ id, idCliente }));
      const clientes = contasAndClientes.map(({ idCliente: id, nomeCliente: nome, siglaCliente: sigla }) => ({ id, nome, sigla }));

      commit("setEmail", email);
      commit("setContas", contas);
      commit("setClientes", clientes);
    },

    async loadConta({ commit, getters: { contaOfCliente } }, idCliente) {
      // atualiza o cliente e já reflete a conta atual dado que só existe uma conta por cliente para um dado usuário
      commit("setCliente", idCliente);
      const { id: idConta } = contaOfCliente(idCliente);
      commit("setConta", idConta);

      const { authenticationOptions, canais } = await endpoints.accountConfirmation.request(idConta);
      const channels = canais.map(({ mascara: mask, tipo }) => ({ mask, tipo }));

      // dados para dar prosseguimento com autenticação via web-authn
      commit("setWebAuthnAuthenticationOptions", authenticationOptions);
      // dados para dar prosseguimento com autenticação via otp
      commit("setChannels", channels);
    },
  };
}
