export function decodeManager(backendManager) {
  const { celular, codigo, email, id, idCliente, nome, perfil: perfilServer, tipoCodigo, tipoConta } = backendManager;
  const { ativo, nome: perfil } = perfilServer;

  return { ativo, celular, codigo, email, id, idCliente, nome, perfil, tipoCodigo, tipoConta };
}

export function encodeManager(frontendManager) {
  const { ativo = true, celular, codigo, email, id,
    idCliente, nome, perfil: perfilClient = "GESTOR", tipoCodigo, tipoConta = "PESSOA" } = frontendManager;

  const perfil = { ativo, nome: perfilClient };

  return { celular, codigo, email, id, idCliente, nome, perfil, tipoCodigo, tipoConta };
}
