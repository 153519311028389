import { createBaseStoreModule } from "../base";
import { createItemsFeatures } from "./items";
import { createPollingFeatures } from "./polling";
import { createQueryFeatures } from "./query";

/*
  concentra a criação de store modules de items de negócios oferecendo
  defaults sem remover a flexibilidade de estender os módulos.

  o objetivo é centralizar código comum reduzindo a superfície de erros
  e manutenção nas data stores.
*/

export function createCollectionStoreModule(config) {
  const {
    actions: actionsConfig,
    getters: gettersConfig,
    mutations: mutationsConfig,
    state: stateConfig,
  } = config;

  const {
    getters: gettersItems,
    mutations: mutationsItems,
    state: stateItems,
  } = createItemsFeatures(config);

  const {
    actions: actionsQuery,
    getters: gettersQuery,
    mutations: mutationsQuery,
    state: stateQuery,
  } = createQueryFeatures(config);

  const {
    actions: actionsPolling,
    getters: gettersPolling,
    mutations: mutationsPolling,
    state: statePolling,
  } = createPollingFeatures(config);

  return createBaseStoreModule({
    actions: {
      ...actionsQuery,
      ...actionsPolling,
      ...actionsConfig,
    },
    getters: {
      ...gettersItems,
      ...gettersPolling,
      ...gettersConfig,
      ...gettersQuery,
    },
    mutations: {
      ...mutationsItems,
      ...mutationsPolling,
      ...mutationsConfig,
      ...mutationsQuery,
    },
    state: {
      ...stateItems,
      ...statePolling,
      ...stateQuery,
      ...stateConfig,
    },
  });
}
