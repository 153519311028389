import { createHttp } from "@/lib";

export function createwebLoggerEndpoint({ environment }) {
  const webLoggerUrl = environment.get("WEB_WEB_LOGGER_URL");
  if (!webLoggerUrl) return null;

  const http = createHttp({ baseUrl: webLoggerUrl });
  const endpoint = http.endpoint({ method: "post", url: "/" });
  return endpoint;
}
