import { SYSLOG_SEVERITIES, consoleTrigger } from "@/lib";

export function createEffects({ environment, webLoggerEndpoint }) {
  const effects = [];

  const consoleEffect = createConsoleEffect(environment);
  if (consoleEffect) effects.push(consoleEffect);

  const webLoggerEffect = createWebLoggerEffect({ environment, webLoggerEndpoint });
  if (webLoggerEffect) effects.push(webLoggerEffect);

  return effects;
}

function createConsoleEffect(environment) {
  const consoleSeverityKey = environment.get("WEB_CONSOLE_SEVERITY_KEY", "INFO");
  const consoleSeverity = SYSLOG_SEVERITIES[consoleSeverityKey];

  const consoleEffect = { severity: consoleSeverity, trigger: consoleTrigger };
  return consoleEffect;
}

function createWebLoggerEffect({ environment, webLoggerEndpoint }) {
  if (!webLoggerEndpoint) return;

  const webLoggerSeverityKey = environment.get("WEB_WEB_LOGGER_SEVERITY_KEY", "NOTICE");
  const webLoggerSeverity = SYSLOG_SEVERITIES[webLoggerSeverityKey];

  const webLoggerTrigger = log => webLoggerEndpoint.request(log);

  const webLoggerEffect = { severity: webLoggerSeverity, trigger: webLoggerTrigger };
  return webLoggerEffect;
}
