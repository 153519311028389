export function createGroupsEndpoints({ http, pageLimit }) {
  const groupsUrl = "/v1/grupos";
  return {
    groupAdd: http.endpoint({
      method: "post",
      url: groupsUrl,
    }),
    groupEdit: http.endpoint({
      encode: clientGroup => ({ payload: clientGroup, url: `${groupsUrl}/${clientGroup.id}` }),
      method: "put",
    }),
    groupsList: http.endpoint({
      encode: page => ({ url: `${groupsUrl}?limit=${pageLimit}&offset=${page * pageLimit}` }),
      method: "get",
    }),
  };
}
